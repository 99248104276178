



























import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuItem } from "@/components/Menu/menu-item";

@Component({
  components: {
    MenuOption: () => import("@/components/Menu/option/menu-option.vue"),
    MenuGroup: () => import("@/components/Menu/group/menu-group.vue"),
  },
})
export default class MenuGroup extends Vue {
  @Prop() public item!: MenuItem;
  @Prop() public rootGroup!: boolean;
  public value: boolean = false;
}
